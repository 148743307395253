import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Faq from '@/components/Faq/Faq';
import { Box } from '@/components/Box/Box';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/medical-loan.json';
import PLData from '@/data/pages/personal-loan.json';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { FeaturesSlider } from '@latitude/features-slider';
import {
  BREAKPOINT,
  COLOR,
  SOFT_QUOTE_LOANS_AU_URL,
  PL_APPLY_CTA_TRACK_ID,
} from '@/utils/constants';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import footerData from '@/data/json/footer.json';
import heroImage from '../images/hero/medical-loan.webp';
import SvgInline from '@latitude/svg-inline';
import Button from '../components/Button/Button';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { AnalyticsContext } from 'latitude-analytics';
import Section from '@latitude/section';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator2/PersonalLoanCalculator';
import ProductComparisonSection from '@/components/PersonalLoanPageContent/ProductComparisonSection';
import CardCollection from '@/components/lab-components/CardCollection';
import ProofPointCollection from '@/components/lab-components/ProofPointCollection';
import ApplyEligibilitySections from '@/components/ApplyEligibilitySections/ApplyEligibilitySections.jsx';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;
const CTA_BUTTON_LABEL = 'Start here';
const COLOUR_PALE_BLUE = '#E5F6FF';

const MedicalLoanPage = ({ data, location }) => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  console.log("state med", state);

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */
    
  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'medical-loan',
    page_pageType: 'product-details-page',
    page_siteSection: 'loans',
    page_siteSubsection: 'medical-loan',
    product_productid: ["PLAULF-WEB"]
  };

  useEffect(() => {
    sessionStorage.setItem('purpose', 'medical-dental'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  function transformData(data) {
    return {
      title: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'heading-3',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Recommended reads',
                marks: [],
                data: {}
              }
            ]
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {}
              }
            ]
          }
        ]
      },
      cards: data?.map(item => ({
        image: {
          file: {
            url: `/life-done-better/images/${item?.node?.frontmatter?.thumbnail}`
          }
        },
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: item?.node?.frontmatter?.title,
                  nodeType: 'text'
                }
              ],
              nodeType: 'paragraph'
            }
          ],
          nodeType: 'document'
        },
        tileUrl: item?.node?.frontmatter?.path
      }))
    };
  }

  return (
    <Layout
      location={location}
      metaDesc={PageData.metaDesc}
      title={PageData.title}
      canonical= 'https://www.latitudefinancial.com.au/medical-loan/'
      customFooter={footerData}
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              title={'Medical Loans'}
              subTitle={
                <>
                  Time to invest in your health?
                  <br />
                  Take control of your finances. Apply online and get a response
                  in 60 seconds.
                </>
              }
              pageImage={heroImage}
              pageImageAlt="loan for medical"
            >
              <div className="text-white row">
                <div className="text-left col-6 col-md-5">
                  <HomeButton
                    href={applyUrl}
                    trackId="medical-loans-button"
                    trackEventData={{
                      label: CTA_BUTTON_LABEL,
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLAULF-WEB']}
                    className="button--primary"
                  >
                    {CTA_BUTTON_LABEL}
                  </HomeButton>
                </div>
                <div className="p-0 text-center col-1">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="pt-1 text-left col-5 col-md-6 padding-right HeroContent__insettext">
                  Find out your estimated interest rate in 2 minutes
                </div>
              </div>
            </PageHeaderSection>
          )}

          <StickyNavContainer>
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber={PLData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaText={CTA_BUTTON_LABEL}
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
              offsetElem="[data-sticky-navigation-offset]"
              {...state?.inPageNavData?.[0]}
            />
          </StickyNavContainer>
   
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="why-us"
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
            className="pt-4 why-choose pt-md-5"
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            css={`
              background-color: #e6e6e6;
              && ul {
                text-align: left;
                @media (max-width: ${BREAKPOINT.MD}) {
                  padding: 2px 20px 20px 20px;
                  text-align: center;
                  list-style-position: inside;
                }
              }
              && h4 {
                text-align: left;
                font-size: 32px;
                line-height: 36px;
                margin: 0px;
                @media (max-width: ${BREAKPOINT.MD}) {
                  text-align: center;
                }
              }
              &&.lfs-features-slider {
                padding: 32px 0px;
              }
              && h4 > p {
                padding: 0px;
                margin: 0px;
              }
              && div.lfs-card-icon {
                padding: 0;
                width: 120px !important;
                height: 120px !important;
                @media (max-width: ${BREAKPOINT.MD}) {
                  margin-bottom: 0px;
                }
              }
              && svg {
                border-radius: 50%;
                background-color: white;
                padding: 0px 23px;
              }
              && .lfs-card-text {
                @media (max-width: ${BREAKPOINT.MD}) {
                  margin-top: 0px;
                }
              }
              && .lfs-card {
                @media (max-width: ${BREAKPOINT.MD}) {
                  gap: 16px;
                }
              }
            `}
          />

          <ApplyEligibilitySections 
            title="What can I use my medical loan for?"
            description="You can use your Latitude medical loan if you're borrowing for one of the below categories:"
            id="medical-loan-uses"
            data={PageData?.applyEligibilitySections}
          />
          <Section
            id="repayment-calculator"
            heading="Get an estimate of your repayments"
            css={`
              background-color: ${COLOUR_PALE_BLUE};
              padding-top: 32px;
              padding-bottom: 32px;
              && h2.text-center {
                text-align: left !important;
                font-size: 32px;
                line-height: 36px;
                margin-top: 0px;
                margin-bottom: 30px;
              }
            `}
          >
            <PersonalLoanCalculator
              loans1
              ctaButtonLabel={CTA_BUTTON_LABEL}
              applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
            />
          </Section>
          <ProductComparisonSection
            id="compare-products"
            heading="Compare our fixed and variable loan options to find the right fit"
          />
          <ProofPointCollection
            id="calculators-and-tools"
            data={
              state?.proofPointCollectionData?.[0] ||
              PageData?.contentful?.proofPointCollectionData?.[0]
            }
          />
          <CardCollection
            id="applying-is-simple"
            data={
              state?.cardCollectionData?.[0] ||
              PageData?.contentful?.cardCollectionData?.[0]
            }
          />
          <CardCollection
            id="Recommended reads"
            data={
              state?.cardCollectionData?.[1] ||
              transformData(pageQuery?.allMarkdownRemark?.edges)
            }
            category="text-link"
          />
          <FaqSection contentful={state?.faqData?.[0]} />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            {...state?.importantInfoData?.[0]}
            css={`
              .important-info svg {
                stroke-width: unset;
              }
              .important-info__header > h3 {
                @media (min-width: 992px) {
                  margin: 0 0 0 6px;
                }
              }
            `}
          />
        </Box>
      </main>
    </Layout>
  );
};

// StickyNav ///////////////////////////////////////////////////////////////////
const StickyNavContainer = styled.div`
  display: none;
  position: relative;
  z-index: 10;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
  }
`;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "Let's keep healthy over Christmas! Wait, what?"
              "Destination healthy: The Health Benefits of Travel"
              "How to Fund Your Dream Vacation"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
          }
        }
      }
    }
  }
`;

// Faqs ////////////////////////////////////////////////////////////////////////
// TODO
const FaqSection = ({ contentful }) => {
  // use faq data from Personal loan page
  const faqData = PageData.faq;

  // modify first faq answer to be medical loan specific
  faqData[0].qa[0].a = customAnswer();

  function customAnswer() {
    let string = `You can use your Latitude Medical Loan for medical procedures if you're borrowing for one of the below health categories:<br />
      <br />
      <ul>
    `;
    PageData.healthCategories.map(item => {
      string += `<li>${item}</li>`;
    });
    string += `</ul>
      <br />
      If you have something in mind, or want more information on Personal Loans for medical and surgical procedures, give us a call <a href="tel:132826">13 28 26</a> to discuss a solution that suits you.
    `;
    return string;
  }

  return <Faq data={faqData} {...contentful}/>;
};

export default MedicalLoanPage;
